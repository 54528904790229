import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { ColumnSeparator, Container, CustomTableCell } from './styles';

export interface CustomGridColumns {
  field: string;
  headerName: string;
  width: number;
  minWidth?: number;
  align?: string;
  fixed?: boolean;
  hide?: boolean;
  sortable?: boolean;
  disableColumnMenu?: boolean;
  renderCell?: (row: any) => void;
  fixedDirection?: string;
}

interface FormProps {
  rows: any[];
  columns: CustomGridColumns[];
  stickyMenuBackground?: string;
}

export const CustomDataGrid: React.FC<FormProps> = ({
  rows,
  columns,
  stickyMenuBackground = '',
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const tableRef = useRef<any>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef.current.scrollTop = 0;
  };

  useEffect(() => {
    if (rows.length === 0) {
      setPage(0);
    }
  }, [rows]);

  const visibleColumns = columns.filter((column) => !column.hide);

  const createCell = (column: any, row: any) => {
    const cellContent = column.renderCell
      ? column.renderCell(row)
      : row[column.field];
    if (column.fixed) {
      return (
        <CustomTableCell
          key={column.field}
          background={stickyMenuBackground}
          columnWidth={column.width}
          style={{
            position: column.fixed ? 'sticky' : 'relative',
            right: column.fixed ? 0 : undefined,
            textAlign: column.fixed ? 'center' : undefined,
          }}
        >
          {cellContent}
        </CustomTableCell>
      );
    }
    return <TableCell key={column.field}>{cellContent}</TableCell>;
  };

  return (
    <Container>
      <Paper
        style={{
          width: '100%',
          maxHeight: '18rem',
          height: '18rem',
          flex: 1,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <LinearProgress
          className="disabled-linear-progress"
          style={{
            width: '100%',
            top: '60px',
            height: '5px',
            margin: 'auto',
            zIndex: 4,
            background: 'transparent',
            color: 'transparent',
          }}
        />
        <TableContainer style={{ flex: 1 }} ref={tableRef}>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.headerName}
                  style={{ borderColor: '#8850BF' }}
                >
                  {visibleColumns.map((column) => createCell(column, row))}
                </TableRow>
              ))}
            </TableBody>
            <TableHead className="headerPosition">
              <TableRow>
                {visibleColumns.map((column) => (
                  <TableCell
                    key={column.field}
                    className="text-truncate"
                    style={{
                      position: column.fixed ? 'sticky' : 'relative',
                      right: column.fixed ? 0 : undefined,
                      width: column.width,
                      minWidth: column.width,
                      background: column.fixed ? '#57069e' : undefined,
                      zIndex: column.fixed ? 99999 : 99998,
                      textAlign: column.fixed ? 'center' : undefined,
                    }}
                  >
                    {column.headerName}
                    {!column.fixed && (
                      <ColumnSeparator>
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>
                      </ColumnSeparator>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};
