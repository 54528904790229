import React from 'react';

import { Container } from './styles';

import { useFinalizadora, useFormContext } from '../../../FinalizadoraContext';
import { FormFinalizadoraLoja } from '../../../protocols';
import { toast } from 'react-toastify';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const FormActions: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const {
    formFinalizadora,
    formFinalizadoraLoja,
    onChangeFormFinalizadora,
    handleInitLoja,
    onEdit,
    handleClearFormFinalizadoraLoja,
  } = useFinalizadora();

  const {
    handleSubmitFinalizadora,
    getValuesFinalizadora,
    formStateFinalizadora,
  } = useFormContext();

  const checkIfHasExists = (): boolean => {
    const hasExists = formFinalizadora.lojas.lojas.find(
      (loja: FormFinalizadoraLoja) => {
        return loja.cod_loja.value === formFinalizadoraLoja.cod_loja.value;
      },
    );
    if (hasExists) return true;
    return false;
  };

  const chamaSwetAlert = () => {
    const { des_loja } = getValuesFinalizadora();
    MySwal.fire({
      title: ``,
      text: `Já existe um registro da Loja "${des_loja}" vinculado a grade. Deseja altera-lo?`,

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        onUpdateFinalizadoraLoja();
      }
    });
  };

  const onAddFinalizadoraLoja = () => {
    const finalizadoraValue = getValuesFinalizadora();
    const formFinalizadoraValue: FormFinalizadoraLoja = {
      cod_loja: {
        value: finalizadoraValue.cod_loja,
        isInvalid: false,
        isRequired: false,
      },
      des_loja: {
        value: finalizadoraValue.des_loja,
        isInvalid: false,
        isRequired: false,
      },
      des_tecla: {
        value: finalizadoraValue.des_tecla,
        isInvalid: false,
        isRequired: true,
      },
      num_condicao: {
        value: finalizadoraValue.num_condicao,
        isInvalid: false,
        isRequired: false,
      },
      cod_condicao: {
        value: finalizadoraValue.cod_condicao,
        isInvalid: false,
        isRequired: true,
      },
      cod_cc: {
        value: {
          value: finalizadoraValue.cod_cc.value,
          label: finalizadoraValue.cod_cc.label,
        },
        isInvalid: true,
        isRequired: true,
      },
      cod_categoria: {
        value: finalizadoraValue.cod_categoria,
        isInvalid: true,
        isRequired: true,
      },
      flg_inativa: {
        value: finalizadoraValue.flg_inativa || false,
        isInvalid: false,
        isRequired: false,
      },
    };
    onChangeFormFinalizadora({
      ...formFinalizadora,
      cod_finalizadora: {
        ...formFinalizadora.cod_finalizadora,
        value: finalizadoraValue.cod_finalizadora,
      },
      des_finalizadora: {
        ...formFinalizadora.des_finalizadora,
        value: finalizadoraValue.des_finalizadora,
      },
      tipo_finalizadora: {
        ...formFinalizadora.tipo_finalizadora,
        value: finalizadoraValue.tipo_finalizadora,
      },
      lojas: {
        ...formFinalizadora.lojas,
        lojas: [...formFinalizadora.lojas.lojas, formFinalizadoraValue],
      },
    });

    handleClearFormFinalizadoraLoja();
  };

  const onUpdateFinalizadoraLoja = (): void => {
    const finalizadoraValue = getValuesFinalizadora();
    const formFinalizadoraValue: FormFinalizadoraLoja = {
      cod_loja: {
        value: finalizadoraValue.cod_loja,
        isInvalid: false,
        isRequired: false,
      },
      des_loja: {
        value: finalizadoraValue.des_loja,
        isInvalid: false,
        isRequired: false,
      },
      des_tecla: {
        value: finalizadoraValue.des_tecla,
        isInvalid: false,
        isRequired: true,
      },
      num_condicao: {
        value: finalizadoraValue.num_condicao,
        isInvalid: false,
        isRequired: false,
      },
      cod_condicao: {
        value: finalizadoraValue.cod_condicao,
        isInvalid: false,
        isRequired: true,
      },
      cod_cc: {
        value: {
          value: finalizadoraValue.cod_cc.value,
          label: finalizadoraValue.cod_cc.label,
        },
        isInvalid: true,
        isRequired: true,
      },
      cod_categoria: {
        value: finalizadoraValue.cod_categoria,
        isInvalid: true,
        isRequired: true,
      },
      flg_inativa: {
        value: finalizadoraValue.flg_inativa || false,
        isInvalid: false,
        isRequired: false,
      },
    };

    const data = formFinalizadora.lojas.lojas.map(
      (finalizadoraLoja: FormFinalizadoraLoja) => {
        if (
          finalizadoraLoja.cod_loja.value ===
          formFinalizadoraLoja.cod_loja.value
        ) {
          finalizadoraLoja = formFinalizadoraValue;
        }
        return finalizadoraLoja;
      },
    );

    onChangeFormFinalizadora({
      ...formFinalizadora,
      lojas: {
        ...formFinalizadora.lojas,
        lojas: data,
      },
    });

    handleClearFormFinalizadoraLoja();
  };

  const handleAddFinalizadoraLoja = handleSubmitFinalizadora(
    async (data: any): Promise<any> => {
      if (!formFinalizadoraLoja.cod_loja.value) {
        return toast.warning('A loja deve ser informada.');
      }
      handleInitLoja(false);
      const hasExists = checkIfHasExists();
      if (!hasExists) {
        return onAddFinalizadoraLoja();
      }
      if (onEdit) {
        return onUpdateFinalizadoraLoja();
      }
      if (
        formFinalizadoraLoja.des_loja.value !== '' &&
        formFinalizadoraLoja.des_loja.value !== undefined &&
        formFinalizadoraLoja.des_loja.value !== null
      ) {
        chamaSwetAlert();
      }
    },
  );

  const onClearFormFinalizadora = () => {
    handleClearFormFinalizadoraLoja();
    handleInitLoja(false);
  };

  return (
    <Container>
      <ConfirmButton
        onClick={() => {
          handleInitLoja(true);
          handleAddFinalizadoraLoja();
        }}
      >
        Confirmar
      </ConfirmButton>
      <CancelButton onClick={onClearFormFinalizadora}>Cancelar</CancelButton>
    </Container>
  );
};

export default FormActions;
